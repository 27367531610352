import React from "react";
import Layout from "../components/layout";

export default function FourOhFour() {
  return (
    <Layout>
      <h1>Uh we couldn&apos;t find the page you&apos;re looking for.</h1>
    </Layout>
  );
}
